import { User, UserRoles, ScopedRole } from '../generated/graphql';
import { hasScopedRole, toScopedRole, toGlobalScopedRole } from './scoped-role';
import { takeAwayMaybeElement } from './array';
import url from '../constants/url';

// 複数のコンポーネントで使用される「ロールのドメインに対する操作権限」を一元管理する
// （最終的に second-app の graphpq/permissions.js と共通化できるとよさげ）
export type Permissions = {
  URL: any;
  Company: {
    Setting: {
      write: boolean;
    };
  };
  Objective: {
    Company: {
      write: boolean;
    };
  };
  Group: {
    read: boolean;
    write: boolean;
  };
  UserAttributes: {
    read: boolean;
    write: boolean;
  };
  GroupTag: {
    read: boolean;
    write: boolean;
  };
  GroupTagMember: {
    read: boolean;
    write: boolean;
  };

  // UI (たとえばサイドバーの表示／非表示) に関しても、ここで一元管理できてもよいかも
  UI: {
    Action: {
      CreateBulkCsv: boolean;
      BulkPublishFeedback: boolean;
    };
    Sidebar: {
      SelfMananement: boolean;
      PeopleMananement: boolean;
      Organization: boolean;
      Settings: boolean;
      HR: boolean;
      HRScopedTeam: boolean;
      HrDashboard: boolean;
      Account: boolean;
      Ppo3: boolean;
    };
    Hr: {
      Dashboard: boolean;
      PeopleMananement: {
        Index: boolean;
        Objectives: boolean;
        O3s: boolean;
        Feedbacks: boolean;
        ObjectiveSheets: boolean;
        ExtMentors: boolean;
      };
      Platform: {
        Index: boolean;
        Members: boolean;
        Teams: boolean;
        ReportLines: boolean;
        GroupTags: boolean;
        UserAttributes: boolean;
      };
    };
    Ppo3: {
      Organization: {
        Index: boolean;
        Company: boolean;
        Teams: boolean;
        Individual: boolean;
      };
      Settings: {
        Index: boolean;
        O3s: boolean;
        PreSurvey: boolean;
        Guideline: boolean;
      };
      Platform: {
        Index: boolean;
        Members: boolean;
        Teams: boolean;
        ReportLines: boolean;
      };
    };
  };
};

export type Authorities = {
  hrScopedRoles: UserRoles[];

  hasScopedHrManagementRole: boolean;
  hasScopedHrDataExportRole: boolean;
  hasScopedHrSettingRole: boolean;
  hasAnyScopedHrRole: boolean;

  hasGlobalOwnerRole: boolean;
  hasGlobalMemberRole: boolean;
  hasGlobalAccountManagementRole: boolean;
  hasGlobalHrManagementRole: boolean;
  hasGlobalHrDataExportRole: boolean;
  hasGlobalHrSettingRole: boolean;
  hasAnyGlobalHrRole: boolean;

  hasOwnerRole: boolean;
  hasMemberRole: boolean;
  hasHrRole: boolean;
  hasAccountManagementRole: boolean;
  hasHrManagementRole: boolean;
  hasHrDataExportRole: boolean;
  hasHrSettingRole: boolean;
  isCompanyOwner: boolean;
};

export const genAuthorities = (me: User, teamId: string | null = null): Authorities => {
  const scopedRoles = takeAwayMaybeElement<ScopedRole[]>(me.scopedRoles);

  // teamIdは必須となる
  const hasScopedHrManagementRole =
    !!teamId && hasScopedRole(me, toScopedRole(UserRoles.HrManagement, scopedRoles, teamId));
  const hasScopedHrDataExportRole =
    !!teamId && hasScopedRole(me, toScopedRole(UserRoles.HrDataExport, scopedRoles, teamId));
  const hasScopedHrSettingRole =
    !!teamId && hasScopedRole(me, toScopedRole(UserRoles.HrSetting, scopedRoles, teamId));

  const hasAnyScopedHrRole = (() => {
    const _hrManagement = hasScopedRole(
      me,
      toScopedRole(UserRoles.HrManagement, scopedRoles, null),
    );
    const _hrDataExport = hasScopedRole(
      me,
      toScopedRole(UserRoles.HrDataExport, scopedRoles, null),
    );
    const _hrSetting = hasScopedRole(me, toScopedRole(UserRoles.HrSetting, scopedRoles, null));
    return _hrManagement || _hrDataExport || _hrSetting;
  })();

  const hasGlobalOwnerRole = hasScopedRole(me, toGlobalScopedRole(me, UserRoles.Owner));
  const hasGlobalMemberRole = hasScopedRole(me, toGlobalScopedRole(me, UserRoles.Member));
  const hasGlobalAccountManagementRole = hasScopedRole(
    me,
    toGlobalScopedRole(me, UserRoles.AccountManagement),
  );
  const hasGlobalHrManagementRole = hasScopedRole(
    me,
    toGlobalScopedRole(me, UserRoles.HrManagement),
  );
  const hasGlobalHrDataExportRole = hasScopedRole(
    me,
    toGlobalScopedRole(me, UserRoles.HrDataExport),
  );
  const hasGlobalHrSettingRole = hasScopedRole(me, toGlobalScopedRole(me, UserRoles.HrSetting));
  const hasAnyGlobalHrRole =
    hasGlobalAccountManagementRole ||
    hasGlobalHrManagementRole ||
    hasGlobalHrDataExportRole ||
    hasGlobalHrSettingRole;

  const isCompanyOwner = me?.id === me.company?.owner?.id;

  const hrScopedRoles = (() => {
    const roles = [];
    if (hasGlobalAccountManagementRole) {
      roles.push(UserRoles.AccountManagement);
    }
    if (hasGlobalHrManagementRole) {
      roles.push(UserRoles.HrManagement);
    }
    if (hasGlobalHrDataExportRole) {
      roles.push(UserRoles.HrDataExport);
    }
    if (hasGlobalHrSettingRole) {
      roles.push(UserRoles.HrSetting);
    }
    return roles;
  })();

  return {
    // hasScopedOwnerRole,
    // hasScopedAccountManagementRole,
    hasScopedHrManagementRole,
    hasScopedHrDataExportRole,
    hasScopedHrSettingRole,
    hasAnyScopedHrRole,

    hasGlobalOwnerRole,
    hasGlobalMemberRole,
    hasGlobalAccountManagementRole,
    hasGlobalHrManagementRole,
    hasGlobalHrDataExportRole,
    hasGlobalHrSettingRole,
    hasAnyGlobalHrRole,

    //OLD
    hrScopedRoles,
    hasOwnerRole: hasGlobalOwnerRole,
    hasMemberRole: hasGlobalMemberRole,
    hasHrRole: hasAnyGlobalHrRole || hasAnyScopedHrRole,
    hasAccountManagementRole: hasGlobalAccountManagementRole,
    hasHrManagementRole: hasGlobalHrManagementRole || hasScopedHrManagementRole,
    hasHrDataExportRole: hasGlobalHrDataExportRole || hasScopedHrDataExportRole,
    hasHrSettingRole: hasGlobalHrSettingRole || hasScopedHrSettingRole,
    isCompanyOwner,
  };
};

export const genPermissions = (authorities: Authorities): Permissions => {
  const {
    hasHrRole,
    hasOwnerRole,
    hasMemberRole,
    hasAnyScopedHrRole,
    hasGlobalHrSettingRole,
    hasGlobalHrDataExportRole,
    hasAnyGlobalHrRole,
    hasAccountManagementRole,
    hasHrManagementRole,
    hasHrDataExportRole,
    hasHrSettingRole,
    isCompanyOwner,
    hasGlobalHrManagementRole,
  } = authorities;

  return {
    URL: {
      [url.hr.index]: hasHrRole,
      [url.hr.dashboard.index]: hasGlobalHrManagementRole,
      [url.hr.dashboard.overview]: hasGlobalHrManagementRole,
      [url.hr.dashboard.compare]: hasGlobalHrManagementRole,
      [url.hr.dashboard.transition]: hasGlobalHrManagementRole,
      [url.hr.dashboard.menu]: true,

      [url.hr.peopleManagement.index]:
        hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole,
      [url.hr.peopleManagement.objectiveSheet.index]:
        hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole,
      [url.hr.peopleManagement.objectiveSheet.create.index]: hasGlobalHrSettingRole,
      [url.hr.peopleManagement.objectiveSheet.detail.index]:
        hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole,
      [url.hr.peopleManagement.objectiveSheet.detail.status]:
        hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole,
      [url.hr.peopleManagement.objectiveSheet.detail.settings]: hasGlobalHrSettingRole,
      [url.hr.peopleManagement.objectiveSheet.detail.form]: hasHrSettingRole || hasHrManagementRole,
      [url.hr.peopleManagement.objectiveSheet.detail.schedules.index]:
        hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole,

      [url.hr.peopleManagement.objectives.index]:
        hasHrManagementRole || hasGlobalHrDataExportRole || hasGlobalHrSettingRole,
      [url.hr.peopleManagement.objectives.status]: hasHrManagementRole,
      [url.hr.peopleManagement.objectives.company]: hasGlobalHrManagementRole,
      [url.hr.peopleManagement.objectives.team]: hasHrManagementRole,
      [url.hr.peopleManagement.objectives.updateRate]: hasGlobalHrManagementRole,
      [url.hr.peopleManagement.objectives.checkin.index]: hasGlobalHrManagementRole,
      [url.hr.peopleManagement.objectives.checkin.user]: hasGlobalHrManagementRole,
      [url.hr.peopleManagement.objectives.settings]: hasGlobalHrSettingRole,
      [url.hr.peopleManagement.objectives.guideline.index]: hasGlobalHrSettingRole,
      [url.hr.peopleManagement.objectives.guideline.detail.edit]: hasGlobalHrSettingRole,
      [url.hr.peopleManagement.objectives.category.index]: hasGlobalHrSettingRole,
      [url.hr.peopleManagement.objectives.list]:
        hasGlobalHrDataExportRole || hasGlobalHrManagementRole,

      [url.hr.peopleManagement.o3s.index]:
        hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
      [url.hr.peopleManagement.o3s.status]: hasHrManagementRole,
      [url.hr.peopleManagement.o3s.satisfaction]: hasHrManagementRole,
      [url.hr.peopleManagement.o3s.topics]: hasHrManagementRole,
      [url.hr.peopleManagement.o3s.comment]: hasHrManagementRole,
      [url.hr.peopleManagement.o3s.team]: hasHrManagementRole,
      [url.hr.peopleManagement.o3s.mentor]: hasHrManagementRole,
      [url.hr.peopleManagement.o3s.pairSettings.index]:
        hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole),
      [url.hr.peopleManagement.o3s.surveySettings.index]:
        hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
      [url.hr.peopleManagement.o3s.surveySettings.detail.index]:
        hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),

      [url.hr.peopleManagement.o3s.guideline.index]: hasGlobalHrSettingRole,
      [url.hr.peopleManagement.o3s.guideline.detail.edit]: hasGlobalHrSettingRole,

      [url.hr.peopleManagement.feedback.index]:
        hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole,
      [url.hr.peopleManagement.feedback.feedbacks.index]:
        hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole,
      [url.hr.peopleManagement.feedback.realTimeFeedbacks.index]:
        hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole,
      [url.hr.peopleManagement.feedback.feedbacks.create.index]: hasGlobalHrSettingRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.status]: hasHrManagementRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.forms]: hasHrDataExportRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.pairs]:
        hasHrManagementRole || hasHrSettingRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.templates.index]:
        hasHrManagementRole || hasHrSettingRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.templates.list.index]:
        hasHrManagementRole || hasHrSettingRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.templates.list.detail]:
        hasHrManagementRole || hasHrSettingRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.templates.customize.index]:
        hasHrManagementRole || hasHrSettingRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.templates.requestSettings.index]:
        hasHrManagementRole || hasHrSettingRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.templates.customize.edit]:
        hasHrManagementRole || hasHrSettingRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.templates.customize.create]:
        hasHrManagementRole || hasHrSettingRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.settings.index]:
        hasGlobalHrManagementRole || hasGlobalHrSettingRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.schedules.index]:
        hasHrManagementRole || hasHrSettingRole,
      [url.hr.peopleManagement.feedback.feedbacks.detail.schedules.create.index]:
        hasGlobalHrSettingRole,

      [url.hr.peopleManagement.extMentors.index]: hasAnyGlobalHrRole,

      [url.hr.platform.teams.index]: hasGlobalHrManagementRole || hasGlobalHrSettingRole,
      [url.hr.platform.reportLine.index]: hasGlobalHrManagementRole || hasGlobalHrSettingRole,
      [url.hr.platform.groupTags.index]: hasAnyGlobalHrRole,
      [url.hr.platform.groupTags.members]: hasAnyGlobalHrRole,
      [url.hr.platform.customProperties.index]: hasGlobalHrManagementRole || hasGlobalHrSettingRole,
      [url.hr.platform.customProperties.detail]:
        hasGlobalHrManagementRole || hasGlobalHrSettingRole,
      [url.hr.platform.members.index]: hasAccountManagementRole,
      [url.hr.platform.members.list.index]: hasAccountManagementRole,
      [url.hr.platform.members.list.hrp]: hasAccountManagementRole,
      [url.hr.platform.members.list.owner]: hasAccountManagementRole,
      [url.hr.platform.members.list.hrbp]: hasAccountManagementRole,
      [url.ppo3.settings.pair.team.index]: hasHrSettingRole || hasHrManagementRole,
    },

    Company: {
      Setting: {
        write: isCompanyOwner || hasOwnerRole,
      },
    },
    Objective: {
      Company: {
        write: isCompanyOwner || hasOwnerRole || hasHrManagementRole || hasHrSettingRole,
      },
    },
    Group: {
      read:
        hasAccountManagementRole || hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole,
      write: hasHrManagementRole || hasHrSettingRole,
    },
    UserAttributes: {
      read: hasGlobalHrManagementRole,
      write: hasGlobalHrSettingRole,
    },
    GroupTag: {
      read:
        hasAccountManagementRole || hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole,
      write: hasHrManagementRole || hasHrSettingRole,
    },
    GroupTagMember: {
      read:
        hasAccountManagementRole || hasHrManagementRole || hasHrSettingRole || hasHrDataExportRole,
      write: hasHrManagementRole || hasHrSettingRole,
    },
    UI: {
      Action: {
        CreateBulkCsv: hasAnyGlobalHrRole,
        BulkPublishFeedback: hasAnyGlobalHrRole,
      },
      Sidebar: {
        SelfMananement: hasMemberRole,
        PeopleMananement: hasMemberRole,
        Organization: hasMemberRole,
        Settings: isCompanyOwner || hasOwnerRole,
        HR: hasAnyGlobalHrRole || hasAnyScopedHrRole,
        HRScopedTeam: hasAnyScopedHrRole,
        HrDashboard: hasGlobalHrManagementRole,
        Account: hasMemberRole,
        Ppo3: hasMemberRole,
      },
      Hr: {
        Dashboard: hasHrManagementRole || hasAnyScopedHrRole,
        PeopleMananement: {
          Index: true,
          Objectives: true,
          O3s: hasAnyGlobalHrRole,
          Feedbacks: true,
          ObjectiveSheets: true,
          ExtMentors: hasAnyGlobalHrRole,
        },
        Platform: {
          Index:
            hasAnyGlobalHrRole &&
            (hasAccountManagementRole || hasHrManagementRole || hasHrSettingRole),
          Members: hasAnyGlobalHrRole && hasAccountManagementRole,
          Teams: hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
          ReportLines: hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
          GroupTags: hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
          UserAttributes: hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
        },
      },
      Ppo3: {
        Organization: {
          Index: hasAnyGlobalHrRole && (hasAnyScopedHrRole || hasHrManagementRole), //remove hasAnyGlobalHrRole after api is done
          Company: hasAnyGlobalHrRole && (hasAnyScopedHrRole || hasHrManagementRole),
          Teams: hasAnyGlobalHrRole && (hasAnyScopedHrRole || hasHrManagementRole), //remove hasAnyGlobalHrRole after api is done
          Individual: hasAnyGlobalHrRole && (hasAnyScopedHrRole || hasHrManagementRole),
        },
        Settings: {
          Index: hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
          O3s: hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
          PreSurvey: hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
          Guideline: hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
        },
        Platform: {
          Index:
            hasAnyGlobalHrRole &&
            (hasAccountManagementRole || hasHrManagementRole || hasHrSettingRole),
          Members:
            hasAnyGlobalHrRole &&
            (hasAccountManagementRole || hasHrManagementRole || hasHrSettingRole),
          Teams: hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
          ReportLines: hasAnyGlobalHrRole && (hasHrManagementRole || hasHrSettingRole),
        },
      },
    },
  };
};
