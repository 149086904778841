export type Between = 'none' | 'min' | 'small' | 'normal' | 'medium' | 'large';

export type TypoColor =
  | 'text-01'
  | 'text-02'
  | 'text-03'
  | 'text-04'
  | 'text-05'
  | 'text-error'
  | 'text-success'
  | 'text-disabled'
  | 'text-primary'
  | 'text-secondary'
  | 'text-tertiary';

export const typoColorMap: Record<TypoColor, string> = {
  ['text-01']: 'text-base-01 dark:text-base-01-dark',
  ['text-02']: 'text-base-02 dark:text-base-02-dark',
  ['text-03']: 'text-base-03 dark:text-base-03-dark',
  ['text-04']: 'text-base-04 dark:text-base-04-dark',
  ['text-05']: 'text-base-05 dark:text-base-05-dark',
  ['text-error']: 'text-error dark:text-error-dark',
  ['text-primary']: 'text-primary',
  ['text-secondary']: 'text-secondary',
  ['text-tertiary']: 'text-tertiary',
  ['text-disabled']: 'text-base-03 dark:text-base-03-dark',
  ['text-success']: 'text-success dark:text-success-dark',
};
