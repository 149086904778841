import { FC, memo, ReactNode } from 'react';
import { TextAlign } from './Typo';
import { twMerge } from 'tailwind-merge';
import { Between } from './types';

export type BoxAlignItems = 'start' | 'center' | 'end';
export type BoxBetween = Between;
export type BoxPadding = 'none' | 'min' | 'small' | 'normal' | 'medium' | 'large';
export type BoxGap = 'none' | 'min' | 'small' | 'normal' | 'medium' | 'large';
export type BoxJustifyContent = 'start' | 'center' | 'end';

const boxBetweenMap: Record<BoxBetween, string> = {
  none: '',
  min: 'between-3',
  small: 'between-5',
  medium: 'between-6',
  normal: 'between-7',
  large: 'between-9',
};

const boxPaddingMap: Record<BoxPadding, string> = {
  none: '',
  min: 'p-2',
  small: 'p-3',
  normal: 'p-4',
  medium: 'p-5',
  large: 'p-6',
};

const boxAlignItemsMap: Record<BoxAlignItems, string> = {
  start: 'items-start',
  center: 'items-center',
  end: 'items-end',
};

const boxJustifyContentMap: Record<BoxJustifyContent, string> = {
  center: 'justify-center',
  end: 'justify-end',
  start: 'justify-start',
};

const boxGapMap: Record<BoxGap, string> = {
  none: '',
  min: 'gap-x-3 gap-y-2',
  small: 'gap-4 gap-y-2',
  normal: 'gap-5 gap-y-3',
  medium: 'gap-6 gap-y-3',
  large: 'gap-7 gap-y-4',
};

export interface BoxProps {
  className?: string;
  flex?: boolean;
  flexWrap?: boolean;
  relative?: boolean;
  spaced?: boolean;
  between?: BoxBetween;
  padding?: BoxPadding;
  alignItems?: BoxAlignItems;
  justifyContent?: BoxJustifyContent;
  grow?: boolean;
  align?: TextAlign;
  divided?: boolean;
  fluid?: boolean;
  id?: string;
  gap?: BoxGap;
  children?: ReactNode;
}

const Box: FC<BoxProps> = memo(
  ({
    children,
    className,
    flex = false,
    flexWrap = false,
    relative = false,
    grow = false,
    spaced = false,
    divided = false,
    between = 'normal',
    padding,
    align,
    alignItems,
    justifyContent,
    gap,
    ...props
  }) => {
    let classNames: string[] = [];

    if (flex) {
      classNames.push('flex');
    }

    if (flexWrap) {
      classNames.push('flex-wrap');
    }

    if (relative) {
      classNames.push('relative');
    }

    if (grow) {
      classNames.push('grow');
    }

    if (align) {
      switch (align) {
        default:
          break;
        case 'center':
          classNames.push(`text-center`);
          break;
        case 'left':
          classNames.push(`text-left`);
          break;
        case 'right':
          classNames.push(`text-right`);
          break;
        case 'inherit':
          classNames.push(`text-inherit`);
          break;
      }
    }

    if (spaced) {
      classNames = [...classNames, ...['flex', 'justify-between', 'grow']];
    }

    if (gap) {
      classNames.push(boxGapMap[gap]);
    }

    if (alignItems) {
      classNames.push(boxAlignItemsMap[alignItems]);
    }

    if (justifyContent) {
      classNames.push(boxJustifyContentMap[justifyContent]);
    }

    if (divided) {
      const devidedCn = [
        'border-b border-b-gray20 dark:border-b-gray90 border-solid last:border-0 last:mb-0 last:pb-0',
      ];
      switch (between) {
        case 'none':
        default:
          break;
        case 'min':
          devidedCn.push(...['pb-4 mb-4']);
          break;
        case 'small':
          devidedCn.push(...['pb-5 mb-5']);
          break;
        case 'normal':
          devidedCn.push(...['pb-6 mb-6']);
          break;
        case 'medium':
          devidedCn.push(...['pb-7 mb-7']);
          break;
        case 'large':
          devidedCn.push(...['pb-9 mb-9']);
          break;
      }
      classNames = [...classNames, ...devidedCn];
    } else {
      if (between) {
        classNames.push(`${boxBetweenMap[between]}`);
      }
      if (padding) {
        classNames.push(boxPaddingMap[padding]);
      }
    }

    return (
      <div {...props} className={twMerge(classNames, className)}>
        {children}
      </div>
    );
  },
);

export default Box;
