export function createNewEvent(eventName: string) {
  let event;
  if (typeof Event === 'function') {
    event = new Event(eventName);
  } else {
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }
  return event;
}

export const handlePreventDefault = (e: any) => {
  e.preventDefault;
  e.stopPropagation();
};
