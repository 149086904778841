import i18next from 'i18next';
import {
  CompanyCustomDictionaryWordKeys,
  FeedbackCustomDictionaryWordKeys,
  ObjectiveSheetCustomDictionaryWordKeys,
} from '../generated/graphql';

const Dictionary = {
  [CompanyCustomDictionaryWordKeys.WMentee]: {
    key: CompanyCustomDictionaryWordKeys.WMentee,
    value: i18next.t('メンティ'),
    labelFunction: () => i18next.t('メンティ'),
    descriptionFunction: () => i18next.t('1on1で、相談をする側の社員（部下、メンバー）。話し手。'),
    ja: 'メンティ',
    en: 'Mentee',
  },
  [CompanyCustomDictionaryWordKeys.WMentor]: {
    key: CompanyCustomDictionaryWordKeys.WMentor,
    value: i18next.t('メンター'),
    labelFunction: () => i18next.t('メンター'),
    descriptionFunction: () =>
      i18next.t('1on1で、助言を行う側の社員（上長、マネージャー）。聞き手。'),
    ja: 'メンター',
    en: 'Mentor',
  },
  [CompanyCustomDictionaryWordKeys.WSelfManagement]: {
    key: CompanyCustomDictionaryWordKeys.WSelfManagement,
    value: i18next.t('セルフマネジメント'),
    labelFunction: () => i18next.t('セルフマネジメント'),
    descriptionFunction: () =>
      i18next.t(
        'メンバーが、自身の成長や目標達成のために行う活動を、Wistantでは「セルフマネジメント」と総称しています。',
      ),
    ja: 'セルフマネジメント',
    en: 'Self-management',
  },
  [CompanyCustomDictionaryWordKeys.WPeopleManagement]: {
    key: CompanyCustomDictionaryWordKeys.WPeopleManagement,
    value: i18next.t('ピープルマネジメント'),
    labelFunction: () => i18next.t('ピープルマネジメント'),
    descriptionFunction: () =>
      i18next.t(
        'マネージャーとして、メンバーのサポートやフィードバックを行うことを、Wistantでは「ピープルマネジメント」と総称しています。',
      ),
    ja: 'ピープルマネジメント',
    en: 'People management',
  },
  [CompanyCustomDictionaryWordKeys.WTeam]: {
    key: CompanyCustomDictionaryWordKeys.WTeam,
    value: i18next.t('チーム'),
    labelFunction: () => i18next.t('チーム'),
    descriptionFunction: () =>
      i18next.t(
        'メンバーの所属部署、事業部、プロジェクト・チームなど、企業内の組織単位を表します。',
      ),
    ja: 'チーム',
    en: 'Team',
  },

  [CompanyCustomDictionaryWordKeys.WCheckIn]: {
    key: CompanyCustomDictionaryWordKeys.WCheckIn,
    value: i18next.t('ヘルスチェック'),
    labelFunction: () => i18next.t('ヘルスチェック'),
    descriptionFunction: () =>
      i18next.t(
        'ヘルスチェックは、定期的に目標の進捗状態と必要なサポートについて簡単に共有できる機能です。',
      ),
    ja: 'ヘルスチェック',
    en: 'Check-In',
  },

  [CompanyCustomDictionaryWordKeys.WMenuTitleObjective]: {
    key: CompanyCustomDictionaryWordKeys.WMenuTitleObjective,
    value: i18next.t('目標'),
    labelFunction: () => i18next.t('目標'),
    descriptionFunction: () => i18next.t('（目標の説明）'),
    ja: '目標',
    en: 'Objective',
  },

  [CompanyCustomDictionaryWordKeys.WMenuTitleO3]: {
    key: CompanyCustomDictionaryWordKeys.WMenuTitleO3,
    value: i18next.t('1on1'),
    labelFunction: () => i18next.t('1on1'),
    descriptionFunction: () => i18next.t('（1on1の説明）'),
    ja: '1on1',
    en: '1on1',
  },

  [CompanyCustomDictionaryWordKeys.WMenuTitleFeedback]: {
    key: CompanyCustomDictionaryWordKeys.WMenuTitleFeedback,
    value: i18next.t('フィードバック'),
    labelFunction: () => i18next.t('フィードバック'),
    descriptionFunction: () => i18next.t('（フィードバックの説明）'),
    ja: 'フィードバック',
    en: 'Feedback',
  },

  [CompanyCustomDictionaryWordKeys.WMenuTitleOrganization]: {
    key: CompanyCustomDictionaryWordKeys.WMenuTitleOrganization,
    value: i18next.t('会社・チーム'),
    labelFunction: () => i18next.t('会社・チーム'),
    descriptionFunction: () => i18next.t('（会社・チーム）'),
    ja: '会社・チーム',
    en: 'Organization',
  },

  [CompanyCustomDictionaryWordKeys.WObjectiveSheet]: {
    key: CompanyCustomDictionaryWordKeys.WObjectiveSheet,
    value: i18next.t('ゴール設定シート'),
    labelFunction: () => i18next.t('ゴール設定シート'),
    descriptionFunction: () => i18next.t('（ゴール設定シートの説明）'),
    ja: 'ゴール設定シート',
    en: 'Objective Sheet',
  },
  [CompanyCustomDictionaryWordKeys.WO3sPostSurveyMenteeComment]: {
    key: CompanyCustomDictionaryWordKeys.WO3sPostSurveyMenteeComment,
    value: i18next.t(
      '今回の1on1がより充実したものになるための提案や{{wMentor}}に言いづらい要望などがあれば教えてください。',
    ),
    labelFunction: () => i18next.t('1on1レポートのメンティのコメント'),
    descriptionFunction: () =>
      i18next.t(
        '（1on1レポートのメンティのコメントのタイトル）\n提案やメンターに言いづらい要望など',
      ),
    ja: '今回の1on1がより充実したものになるための提案やメンターに言いづらい要望などがあれば教えてください。',
    en: "Let me know if you have any suggestions to make this 1on1 more fulfilling, or any requests you have that you don't feel comfortable saying to mentor.",
  },
};

export const FeedbackDictionary = {
  [FeedbackCustomDictionaryWordKeys.WSelfLayer]: {
    key: FeedbackCustomDictionaryWordKeys.WSelfLayer,
    value: i18next.t('セルフフィードバック'),
    labelFunction: () => i18next.t('セルフフィードバック'),
    descriptionFunction: () => i18next.t('被評価者本人が自身について行う評価・フィードバック'),
    ja: 'セルフフィードバック',
    en: 'Self Feedback',
  },
  [FeedbackCustomDictionaryWordKeys.WSelfLayerAssignee]: {
    key: FeedbackCustomDictionaryWordKeys.WSelfLayerAssignee,
    value: i18next.t('被評価者'),
    labelFunction: () => i18next.t('被評価者'),
    descriptionFunction: () => i18next.t('評価・フィードバックを受ける対象者'),
    ja: '被評価者',
    en: 'Person being evaluated',
  },
  [FeedbackCustomDictionaryWordKeys.WMiddleLayer]: {
    key: FeedbackCustomDictionaryWordKeys.WMiddleLayer,
    value: i18next.t('中間評価'),
    labelFunction: () => i18next.t('中間評価'),
    descriptionFunction: () =>
      i18next.t(
        '多段階評価における、最終評価以前の評価・フィードバック（１次評価、２次評価、ピアレビュー、etc）',
      ),
    ja: '中間評価',
    en: 'Intermediate evaluation',
  },
  [FeedbackCustomDictionaryWordKeys.WMiddleLayerAssignee]: {
    key: FeedbackCustomDictionaryWordKeys.WMiddleLayerAssignee,
    value: i18next.t('中間評価者'),
    labelFunction: () => i18next.t('中間評価者'),
    descriptionFunction: () =>
      i18next.t('多段階評価における、最終評価以前の評価・フィードバックを行うユーザー'),
    ja: '中間評価者',
    en: 'Intermediate evaluator',
  },
  [FeedbackCustomDictionaryWordKeys.WFinalLayer]: {
    key: FeedbackCustomDictionaryWordKeys.WFinalLayer,
    value: i18next.t('最終評価'),
    labelFunction: () => i18next.t('最終評価'),
    descriptionFunction: () => i18next.t('被評価者に対する最終的な評価・フィードバック'),
    ja: '最終評価',
    en: 'Final evaluation',
  },
  [FeedbackCustomDictionaryWordKeys.WFinalLayerAssignee]: {
    key: FeedbackCustomDictionaryWordKeys.WFinalLayerAssignee,
    value: i18next.t('最終評価者'),
    labelFunction: () => i18next.t('最終評価者'),
    descriptionFunction: () =>
      i18next.t('被評価者に対する最終的な評価・フィードバックを行うユーザー'),
    ja: '最終評価者',
    en: 'Final evaluator',
  },
  [FeedbackCustomDictionaryWordKeys.WReadLayer]: {
    key: FeedbackCustomDictionaryWordKeys.WReadLayer,
    value: i18next.t('閲覧'),
    labelFunction: () => i18next.t('閲覧'),
    descriptionFunction: () => i18next.t('評価・フィードバックの閲覧（シートの記入等は行わない）'),
    ja: '閲覧',
    en: 'View',
  },
  [FeedbackCustomDictionaryWordKeys.WReadLayerAssignee]: {
    key: FeedbackCustomDictionaryWordKeys.WReadLayerAssignee,
    value: i18next.t('閲覧者'),
    labelFunction: () => i18next.t('閲覧者'),
    descriptionFunction: () =>
      i18next.t('評価・フィードバックを閲覧するユーザー（シートの記入等は行わない）'),
    ja: '閲覧者',
    en: 'Viewer',
  },
  [FeedbackCustomDictionaryWordKeys.WSuperviseLayer]: {
    key: FeedbackCustomDictionaryWordKeys.WSuperviseLayer,
    value: i18next.t('管理'),
    labelFunction: () => i18next.t('管理'),
    descriptionFunction: () =>
      i18next.t(
        '評価・フィードバックの閲覧、および、提出済みシートに対する公開等の操作（シートの記入等は行わない）',
      ),
    ja: '管理',
    en: 'Supervise',
  },
  [FeedbackCustomDictionaryWordKeys.WSuperviseLayerAssignee]: {
    key: FeedbackCustomDictionaryWordKeys.WSuperviseLayerAssignee,
    value: i18next.t('管理者'),
    labelFunction: () => i18next.t('管理者'),
    descriptionFunction: () =>
      i18next.t(
        '評価・フィードバックの閲覧、および、提出済みシートに対する公開等の操作を行うユーザー（シートの記入等は行わない）',
      ),
    ja: '管理者',
    en: 'Supervisor',
  },
};

export const ObjectiveSheetDictionary = {
  [ObjectiveSheetCustomDictionaryWordKeys.WObjectiveSheetPerson]: {
    key: ObjectiveSheetCustomDictionaryWordKeys.WObjectiveSheetPerson,
    value: i18next.t('対象者'),
    labelFunction: () => i18next.t('対象者'),
    descriptionFunction: () => i18next.t('シートを記入・提出するユーザー'),
    ja: '対象者',
    en: 'Person',
  },
  [ObjectiveSheetCustomDictionaryWordKeys.WObjectiveSheetApprover]: {
    key: ObjectiveSheetCustomDictionaryWordKeys.WObjectiveSheetApprover,
    value: i18next.t('承認者'),
    labelFunction: () => i18next.t('承認者'),
    descriptionFunction: () => i18next.t('シートの内容をレビュー・承認するユーザー'),
    ja: '承認者',
    en: 'Reviewer',
  },
  [ObjectiveSheetCustomDictionaryWordKeys.WObjectiveSheetReader]: {
    key: ObjectiveSheetCustomDictionaryWordKeys.WObjectiveSheetReader,
    value: i18next.t('閲覧者'),
    labelFunction: () => i18next.t('閲覧者'),
    descriptionFunction: () => i18next.t('シートの内容を閲覧するユーザー'),
    ja: '閲覧者',
    en: 'Reader',
  },
};
export default Dictionary;
