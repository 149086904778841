import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FullscreenModalBody } from '../../../molecules/FullscreenModal';
import AppLogo from '../../../atoms/AppLogo';

import {
  Layout,
  LayoutInner,
  LayoutTopbar,
  LayoutTopbarContent,
} from '../../../molecules/FullscreenModal/styled';
import Box from '../../../quarks/Box';
import Typo from '../../../quarks/Typo';
import Heading from '../../../quarks/Heading';

export const Error503Topbar = () => (
  <LayoutTopbar>
    <LayoutTopbarContent>
      <AppLogo />
    </LayoutTopbarContent>
  </LayoutTopbar>
);

const Error503Wrap = styled.div`
  height: calc(100vh - 162px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Error503Content = styled.div`
  text-align: center;
`;

const Error503 = () => {
  const { t } = useTranslation();
  return (
    <Layout variant="style-01">
      <LayoutInner>
        <Error503Topbar />
        <FullscreenModalBody>
          <Error503Wrap>
            <Error503Content>
              <Box>
                <img
                  src="/img/vector/maintenance.svg"
                  alt="サーバーメンテナンス中"
                  width="286px"
                  height="198px"
                />
              </Box>

              <Box between="small">
                <Heading variant="h1" color="text-02" align="center">
                  {t('サーバーメンテナンス中')}
                </Heading>
              </Box>

              <Box align="center" between="small">
                <Typo pre color="text-02" variant="body-02">
                  {t(
                    'ただいまメンテナンス中のため、サービスを休止しております。\nご迷惑をおかけしますが、メンテナンス終了までお待ちください。',
                  )}
                </Typo>
              </Box>
            </Error503Content>
          </Error503Wrap>
        </FullscreenModalBody>
      </LayoutInner>
    </Layout>
  );
};

export default Error503;
