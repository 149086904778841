import {
  User,
  ResultIndicatorTypes,
  ObjectiveResultTypes,
  ObjectiveSourceTypes,
  Objective,
  Period,
  ObjectiveTemplateItemOptions,
  ObjectiveTemplateResultTypeOptions,
  ObjectiveTemplateIndicatorTypeOptions,
  ObjectiveTemplatePublicationRangeOptions,
  ObjectiveTemplate,
  CompanyObjectiveSetting,
  CustomPublicationRanges,
  Maybe,
  PublicationRanges,
  DomainSourceType,
  ResultIndicatorSourceTypes,
  ObjectiveTemplateAchievementRateTypeOptions,
  OkrAchievementRateTypes,
  OkrStatuses,
  Okr,
  KeyResult,
  ResultIndicator,
  OkrTypes,
  ObjectiveCategory,
  Team,
} from '../generated/graphql';
import pkg from '../../package.json';
import getUnixTime from 'date-fns/getUnixTime';
import { isSameOrAfter, isSameOrBefore } from './date-fns';
import { genAuthorities, genPermissions } from './permission';
import confObjective from '../common/conf/objective';
import { UseFormReturn } from 'react-hook-form';
import i18next from 'i18next';
import { getDecimalLength } from './number';
import { getArrayIds, hasElement, takeAwayEmptyElement, takeAwayMaybeElement } from './array';
import { getActiveUsers } from './user';
import { ObjectiveEditValues } from '../components/organisms/_modals/ObjectiveUpdateField/utils';
import { UseDictionnaryReturn } from '../lib/use-me';
import { getActiveTeams } from './team';
import { ProgressVariant } from '../components/quarks/Progress';

export const getActiveObjectives = (objectives: any[] | undefined | null): Objective[] =>
  takeAwayMaybeElement<Objective[]>(objectives).filter(
    objective => objective.status !== OkrStatuses.Deleted,
  );

export const getActiveKeyResults = (
  keyResults: Maybe<Maybe<KeyResult | undefined>[]> | undefined,
): KeyResult[] =>
  takeAwayMaybeElement<KeyResult[]>(keyResults).filter(
    keyResult => keyResult.status === OkrStatuses.Active,
  );

export const getActiveOkrs = (okrs: Maybe<Maybe<Okr | undefined>[]> | undefined): Okr[] =>
  takeAwayMaybeElement<Okr[]>(okrs).filter(okr => okr.status === OkrStatuses.Active);

export const hasTargetCategory = (okr: Okr, c: ObjectiveCategory) =>
  okr.okrType === OkrTypes.Objective
    ? (okr as Objective).category?.id === c.id
    : okr.okrType === OkrTypes.KeyResult
      ? (okr as KeyResult).parent?.category?.id === c.id
      : false;

export const isSameOkr = (okrA: Okr, okrB: Okr) =>
  okrA.okrType === okrB.okrType && +okrA.id === +okrB.id;

export const objectiveKeyResultVariants: Record<string, ProgressVariant> = {
  keyMetricTag: 'primary',
  keyResult: 'primary',
  objective: 'primary',
  objectiveUser: 'primary',
  objectiveTeam: 'primary',
  objectiveCompany: 'primary',
  default: 'primary',
};

export const getProgressVariant = (
  sourceType:
    | Maybe<DomainSourceType>
    | Maybe<ObjectiveSourceTypes>
    | Maybe<ResultIndicatorSourceTypes>
    | Maybe<OkrTypes>
    | undefined
    | null,
) => {
  switch (sourceType) {
    case ResultIndicatorSourceTypes.KeyResult:
    case DomainSourceType.KeyResult:
    case OkrTypes.KeyResult:
      return objectiveKeyResultVariants.keyResult;

    case ResultIndicatorSourceTypes.Objective:
    case DomainSourceType.Objective:
    case OkrTypes.Objective:
      return objectiveKeyResultVariants.objective;

    case OkrTypes.KeyMetricTag:
      return objectiveKeyResultVariants.keyMetricTag;

    case ObjectiveSourceTypes.User:
    case DomainSourceType.User:
      return objectiveKeyResultVariants.objectiveUser;

    case ObjectiveSourceTypes.Team:
    case DomainSourceType.Team:
      return objectiveKeyResultVariants.objectiveTeam;

    case ObjectiveSourceTypes.Company:
    case DomainSourceType.Company:
      return objectiveKeyResultVariants.objectiveCompany;

    default:
      return objectiveKeyResultVariants.default;
  }
};

interface SearchSourceValue {
  type: string;
  id: string;
}

export type Indicator = {
  id?: string;
  type: ResultIndicatorTypes;
  initialValue: string;
  goalValue: string;
  isDone?: boolean;
  value?: string;
};

const lowestValue = (value: number, initialValue: string, goalValue: string) => {
  const _initialValue = parseFloat(initialValue);
  const _goalValue = parseFloat(goalValue);
  return _goalValue - _initialValue >= 0
    ? Math.max(_initialValue, value)
    : Math.min(_initialValue, value);
};

export const checkIsMeOwner = (objective: Objective, me: User): boolean => {
  if (!objective || !me) {
    return false;
  }
  const permissions = genPermissions(genAuthorities(me));

  // 目標のオーナー
  if (objective?.owners?.some(o => o?.id === me.id)) {
    return true;
  }

  // 個人目標：本人
  if (objective?.user?.id === me.id) {
    return true;
  }

  // チーム目標：チームのマネージャー
  if (objective?.team) {
    return (
      (objective?.team?.enableGlobalCollabolation
        ? true
        : objective?.team?.enableCollabolation
          ? objective.team?.members?.some(m => m?.id! === me.id)
          : objective.team?.managers?.some(m => m?.id! === me.id)) ?? false
    );
  }
  // 会社目標：会社のオーナー、HR分析権限、HR設定権限
  if (objective?.company || objective?.sourceType === ObjectiveSourceTypes.Company) {
    return objective?.company?.owner?.id === me.id || permissions.Objective.Company.write;
  }
  return false;
};

export const checkIsTeamCollabolator = (objective: Objective | null | undefined, me: User) => {
  if (!me || objective?.sourceType !== ObjectiveSourceTypes.Team || !objective?.team) {
    return false;
  }
  return objective?.team?.enableGlobalCollabolation // グローバルコラボレーション
    ? true // 全員
    : objective?.team?.enableCollabolation // チームコラボレーション
      ? objective.team?.members?.some(m => m?.id! === me.id) // チームメンバー
      : objective.team?.managers?.some(m => m?.id! === me.id); // チームマネジャー
};

const generateRateIndicator = (oldIndicatorValue: number) => ({
  type: ResultIndicatorTypes.Rate,
  rate: { value: Math.abs(oldIndicatorValue) },
});

const generateNumberIndicator = (newIndicator: Indicator, oldIndicatorValue: number) => ({
  type: ResultIndicatorTypes.Number,
  number: {
    value: lowestValue(oldIndicatorValue, newIndicator.initialValue, newIndicator.goalValue),
    initialValue: parseFloat(newIndicator.initialValue),
    goalValue: parseFloat(newIndicator.goalValue),
  },
});

const generateBoolIndicator = (indicator: Indicator) => ({
  type: ResultIndicatorTypes.Bool,
  bool: {
    isDone: indicator.isDone || false,
  },
});

export const generateIndicator = (
  resultType: string,
  newIndicator: Indicator,
  oldIndicatorValue: number,
) => {
  switch (resultType) {
    case ObjectiveResultTypes.RateIndicator:
    case ResultIndicatorTypes.Rate: {
      return generateRateIndicator(oldIndicatorValue);
    }
    case ObjectiveResultTypes.NumberIndicator:
    case ResultIndicatorTypes.Number: {
      return generateNumberIndicator(newIndicator, oldIndicatorValue);
    }
    case ResultIndicatorTypes.Bool: {
      return generateBoolIndicator(newIndicator);
    }
    default: {
      return undefined;
    }
  }
};

// 0 - 100 の範囲に丸める ＆ 小数点以下を四捨五入する処理
const getCalculatedAchievementRate = (percent: number) => {
  const min = 0;
  const max = 100;
  return Math.max(min, Math.min(max, confObjective.metrics.rounding(percent)));
};

export const calculateAchievementRateFromValue = (
  value: number,
  initialValue: number,
  goalValue: number,
) => {
  if (!Number.isFinite(value)) return 0;

  if (initialValue === goalValue) {
    return 0;
  }

  const percent = ((value - initialValue) / (goalValue - initialValue)) * 100;
  return getCalculatedAchievementRate(percent);
};

export const calculateAchievementRateFromLog = (parse: any) => {
  const { doneAt, value, initialValue, goalValue } = parse;
  let type = parse.type;

  if (!type) {
    if (doneAt !== undefined) {
      type = 'bool';
    } else if (value !== undefined && initialValue !== undefined && goalValue !== undefined) {
      type = 'number';
    } else if (value !== undefined) {
      type = 'rate';
    } else {
      type = 'none'; //存在しない
    }
  }

  const calc = () => {
    switch (type) {
      case 'bool':
        return doneAt ? 100 : 0;
      case 'rate':
        if (!value) return 0;
        return value;
      case 'number':
        return calculateAchievementRateFromValue(value, initialValue, goalValue);
      default:
        return 0;
    }
  };

  return getCalculatedAchievementRate(calc());
};

export const isObjectiveMatchPeriod = (
  objective: Objective | undefined,
  period: Period | undefined,
) => {
  return (
    isSameOrAfter(objective?.endAt!, period?.startAt!) &&
    isSameOrBefore(objective?.startAt!, period?.endAt!)
  );
};

export const getObjectivePeriodFromDates = (
  periods: Period[],
  startAt: Date | number,
  endAt: Date | number,
) => {
  const startAtUnix = getUnixTime(startAt);
  const endAtUnix = getUnixTime(endAt);
  return (periods || []).find(
    period =>
      getUnixTime(period?.startAt) === startAtUnix && getUnixTime(period?.endAt) === endAtUnix,
  );
};

export const getNumberValueStepFromDecimal = (decimalLength: number) => {
  if (decimalLength < 0) {
    throw new Error('decimal length must be larger or equal to 0');
  }
  if (!Number.isInteger(decimalLength)) {
    throw new Error('decimal length must be an integer');
  }
  return (1 / Math.pow(10, Math.min(2, decimalLength))).toString();
};

export const getNumberValueStepFromValue = (_key: string, _value: number | string) => {
  const value = typeof _value === 'string' ? parseFloat(_value) : _value;
  const key = `${_key}_${pkg.version}`;

  if (isNaN(value)) {
    return '1';
  }

  const numberValueStepFromValueHistory = (window as any).numberValueStepFromValueHistory || {};

  if (numberValueStepFromValueHistory[key] === undefined) {
    numberValueStepFromValueHistory[key] = [];
  }

  const lastHistory =
    numberValueStepFromValueHistory[key][numberValueStepFromValueHistory[key].length - 1];

  if (!lastHistory || +lastHistory !== +value) {
    if (numberValueStepFromValueHistory[key].length >= 2) {
      numberValueStepFromValueHistory[key].shift();
    }
    numberValueStepFromValueHistory[key].push(value);
  }

  if (window) {
    (window as any).numberValueStepFromValueHistory = numberValueStepFromValueHistory;
  }

  if (numberValueStepFromValueHistory[key].length >= 2) {
    const diffValue = parseFloat(
      (numberValueStepFromValueHistory[key][0] - numberValueStepFromValueHistory[key][1]).toFixed(
        2,
      ),
    );
    if (diffValue !== 0) {
      return getNumberValueStepFromDecimal(getDecimalLength(diffValue));
    }
  }

  const decimalLength = getDecimalLength(value);
  return getNumberValueStepFromDecimal(decimalLength);
};

export const calculateAverageWeight = (objectives: Objective[]) =>
  objectives?.length
    ? Math.ceil(
        objectives.map(o => o?.weight! || 0).reduce((total, weight) => total + weight, 0) /
          objectives?.length,
      )
    : 0;

export const calculateTotalWeight = (objectives: Objective[]) =>
  Math.ceil(objectives.map(o => o?.weight! || 0).reduce((total, weight) => total + weight, 0));

export const templateResultTypeToObjectiveResultType = (
  templateResultType: ObjectiveTemplateResultTypeOptions,
) => {
  switch (templateResultType) {
    case ObjectiveTemplateResultTypeOptions.Rate:
      return ObjectiveResultTypes.RateIndicator;
    case ObjectiveTemplateResultTypeOptions.Number:
      return ObjectiveResultTypes.NumberIndicator;
    case ObjectiveTemplateResultTypeOptions.KeyResults:
      return ObjectiveResultTypes.KeyResults;
    case ObjectiveTemplateResultTypeOptions.Any:
    default:
      return ObjectiveResultTypes.RateIndicator;
  }
};

export const templateIndicatorTypeToIndicatorType = (
  templateIndicatorType: ObjectiveTemplateIndicatorTypeOptions,
) => {
  switch (templateIndicatorType) {
    case ObjectiveTemplateIndicatorTypeOptions.Rate:
      return ResultIndicatorTypes.Rate;
    case ObjectiveTemplateIndicatorTypeOptions.Number:
      return ResultIndicatorTypes.Number;
    case ObjectiveTemplateIndicatorTypeOptions.Bool:
      return ResultIndicatorTypes.Bool;
    case ObjectiveTemplateIndicatorTypeOptions.Any:
    default:
      return ResultIndicatorTypes.Bool;
  }
};

export const getTemplate = (
  template: ObjectiveTemplate | null | undefined = undefined,
  sourceType: ObjectiveSourceTypes | null | undefined,
  teamSetting: Partial<Team> | null | undefined,
): ObjectiveTemplate => {
  const {
    id,
    parentOkr,
    parentTeam,
    externalLinks,
    weight,
    achievementRateType,
    resultType,
    keyResultWeight,
    indicatorType,
    indicatorCountMin,
    indicatorCountMax,
    publicationRange,
    publicationRangeDirectManager,
    publicationRangeLineManagers,
    publicationRangeTeam,
  } = template || {};

  const _achievementRateType = !achievementRateType
    ? ObjectiveTemplateAchievementRateTypeOptions.Any
    : achievementRateType;
  let _resultType = !resultType ? ObjectiveTemplateResultTypeOptions.Any : resultType;
  let _keyResultWeight = !keyResultWeight ? ObjectiveTemplateItemOptions.Any : keyResultWeight;
  let _indicatorType = !indicatorType ? ObjectiveTemplateIndicatorTypeOptions.Any : indicatorType;
  let _indicatorCountMin = indicatorCountMin || null;
  let _indicatorCountMax = indicatorCountMax || null;

  if (_achievementRateType === ObjectiveTemplateAchievementRateTypeOptions.Auto) {
    if (_resultType === ObjectiveTemplateResultTypeOptions.Number) {
      _resultType = ObjectiveTemplateResultTypeOptions.Rate;
    }
    if (_resultType === ObjectiveTemplateResultTypeOptions.Rate) {
      _keyResultWeight = ObjectiveTemplateItemOptions.Any;
    }
  }

  //Reset KeyResult Template settings is KeyResults is not selected
  if (_resultType !== ObjectiveTemplateResultTypeOptions.KeyResults) {
    _keyResultWeight = ObjectiveTemplateItemOptions.Any;
    _indicatorType = ObjectiveTemplateIndicatorTypeOptions.Any;
    _indicatorCountMin = null;
    _indicatorCountMax = null;
  }

  return {
    id,
    parentOkr: !parentOkr ? ObjectiveTemplateItemOptions.Any : parentOkr,
    parentTeam: !parentTeam ? ObjectiveTemplateItemOptions.Any : parentTeam,
    externalLinks: !externalLinks ? ObjectiveTemplateItemOptions.Any : externalLinks,
    weight: !weight ? ObjectiveTemplateItemOptions.Any : weight,
    achievementRateType: _achievementRateType,
    resultType: _resultType,
    keyResultWeight: _keyResultWeight,
    indicatorType: _indicatorType,
    indicatorCountMin: _indicatorCountMin,
    indicatorCountMax: _indicatorCountMax,
    publicationRangeDirectManager: !publicationRangeDirectManager
      ? ObjectiveTemplateItemOptions.Any
      : publicationRangeDirectManager,
    publicationRangeLineManagers: !publicationRangeLineManagers
      ? ObjectiveTemplateItemOptions.Any
      : publicationRangeLineManagers,
    publicationRangeTeam: !publicationRangeTeam
      ? ObjectiveTemplateItemOptions.Any
      : publicationRangeTeam,
    publicationRange: (() => {
      if (sourceType === ObjectiveSourceTypes.Company) {
        return ObjectiveTemplatePublicationRangeOptions.Public;
      }

      if (sourceType === ObjectiveSourceTypes.Team) {
        if (teamSetting?.enableCustomPublicationRanges) {
          return ObjectiveTemplatePublicationRangeOptions.Any;
        }
        return ObjectiveTemplatePublicationRangeOptions.Public;
      }

      return publicationRange || ObjectiveTemplatePublicationRangeOptions.Any;
    })(),
  } as ObjectiveTemplate;
};

export const convertInitialValuesWithObjectiveTemplate = (
  initialValues: Record<string, any>,
  _template: Maybe<ObjectiveTemplate> | undefined | null,
  sourceType: ObjectiveSourceTypes | null | undefined,
  teamSetting: Partial<Team> | null | undefined,
) => {
  const template = getTemplate(_template, sourceType, teamSetting);
  const { resultType, indicatorType, keyResultWeight, publicationRange, achievementRateType } =
    template;

  const defaultKeyResults = [
    {
      title: '',
      achievementRateType: ObjectiveTemplateAchievementRateTypeOptions.Manual,
      indicator: {
        type:
          indicatorType === ObjectiveTemplateIndicatorTypeOptions.Any
            ? ResultIndicatorTypes.Bool
            : templateIndicatorTypeToIndicatorType(indicatorType!),
        goalValue: '',
        initialValue: '',
        isDone: false,
      },
    },
  ];

  const _publicationRange = (() => {
    if (sourceType === ObjectiveSourceTypes.Company) {
      return PublicationRanges.Public;
    }

    if (sourceType === ObjectiveSourceTypes.Team) {
      if (teamSetting?.enableCustomPublicationRanges) {
        return initialValues?.publicationRange || PublicationRanges.Public;
      }
      return PublicationRanges.Public;
    }

    if (publicationRange === ObjectiveTemplatePublicationRangeOptions.Public) {
      return PublicationRanges.Public;
    }
    if (publicationRange === ObjectiveTemplatePublicationRangeOptions.Customize) {
      return PublicationRanges.Customize;
    }
    return initialValues?.publicationRange || PublicationRanges.Public;
  })();

  const _useKeyResultWeight =
    keyResultWeight === ObjectiveTemplateItemOptions.Required
      ? true
      : keyResultWeight === ObjectiveTemplateItemOptions.Hidden
        ? false
        : initialValues.useKeyResultWeight;

  const externalLinks = takeAwayEmptyElement<string[]>(
    template.externalLinks !== ObjectiveTemplateItemOptions.Hidden
      ? initialValues.externalLinks
      : null,
  );

  const adaptedValues = {
    title: initialValues.title,
    description: initialValues.description,

    periodId: initialValues.periodId,
    categoryId: initialValues.categoryId,
    publicationRange: _publicationRange,
    customPublicationRanges: initialValues.customPublicationRanges || [],
    ownerIds: initialValues.ownerIds,
    parent:
      template.parentOkr !== ObjectiveTemplateItemOptions.Hidden
        ? !!initialValues?.parent?.sourceId && !!initialValues?.parent?.sourceType
          ? {
              sourceId: initialValues.parent?.sourceId,
              sourceType: initialValues.parent?.sourceType,
            }
          : null
        : null,
    weight: template.weight !== ObjectiveTemplateItemOptions.Hidden ? initialValues.weight : null,
    externalLinks: hasElement(externalLinks) ? externalLinks : [''],
    parentTeamId:
      template.parentTeam !== ObjectiveTemplateItemOptions.Hidden
        ? initialValues.parentTeamId
        : initialValues.parentTeamId,
    achievementRateType: initialValues.achievementRateType,
    resultType: initialValues.resultType,
    useKeyResultWeight: _useKeyResultWeight,
    indicator: initialValues.indicator,
    sourceType: initialValues.sourceType,
    sourceId: initialValues.sourceId,
    sheetId: initialValues.sheetId,
    sheetItemTemplateId: initialValues.sheetItemTemplateId,
  } as ObjectiveEditValues;

  // TODO: 単体の関数に切り出してユニットテストを書くべき
  if (resultType === ObjectiveTemplateResultTypeOptions.Rate) {
    adaptedValues.useKeyResultWeight = false;
    adaptedValues.resultType = ObjectiveResultTypes.RateIndicator;
    if (initialValues?.indicator?.type !== ObjectiveTemplateResultTypeOptions.Rate) {
      adaptedValues.indicator = undefined;
    }
  } else if (resultType === ObjectiveTemplateResultTypeOptions.Number) {
    adaptedValues.useKeyResultWeight = false;
    adaptedValues.resultType = ObjectiveResultTypes.NumberIndicator;
    if (initialValues?.indicator?.type !== ObjectiveTemplateResultTypeOptions.Number) {
      adaptedValues.indicator = undefined;
    }
  } else if (resultType === ObjectiveTemplateResultTypeOptions.KeyResults) {
    adaptedValues.useKeyResultWeight = _useKeyResultWeight;
    adaptedValues.resultType = ObjectiveResultTypes.KeyResults;
    adaptedValues.indicator = undefined;
    adaptedValues.keyResults = (
      hasElement(initialValues?.keyResults)
        ? takeAwayEmptyElement(initialValues?.keyResults).filter(keyResult => {
            if (template?.indicatorType === ObjectiveTemplateIndicatorTypeOptions.Any) {
              return true;
            }
            return (
              keyResult?.indicator?.type ===
              templateIndicatorTypeToIndicatorType(template?.indicatorType!)
            );
          })
        : defaultKeyResults
    ).map((keyResult: any) => {
      const updatedKeyResult = { ...keyResult };
      if (keyResultWeight === ObjectiveTemplateItemOptions.Hidden) {
        updatedKeyResult.weight = null;
      }
      return updatedKeyResult;
    });
  } else if (resultType === ObjectiveTemplateResultTypeOptions.Any) {
    adaptedValues.useKeyResultWeight = _useKeyResultWeight;
    adaptedValues.indicator = initialValues?.indicator;
    adaptedValues.keyResults = hasElement(initialValues?.keyResults)
      ? initialValues?.keyResults
      : defaultKeyResults;
  } else {
    adaptedValues.useKeyResultWeight = _useKeyResultWeight;
    adaptedValues.indicator = initialValues?.indicator;
    adaptedValues.keyResults = hasElement(initialValues?.keyResults)
      ? initialValues?.keyResults
      : defaultKeyResults;
  }

  if (_publicationRange === ObjectiveTemplatePublicationRangeOptions.Public) {
    adaptedValues.customPublicationRanges = [];
  }

  if (achievementRateType === ObjectiveTemplateAchievementRateTypeOptions.Auto) {
    adaptedValues.achievementRateType = OkrAchievementRateTypes.Auto;
    if (adaptedValues.resultType === ObjectiveResultTypes.NumberIndicator) {
      adaptedValues.resultType = ObjectiveResultTypes.RateIndicator;
    }
  }

  return adaptedValues;
};

export const isPublicationRequired = (
  targetPublicationRange: CustomPublicationRanges,
  args: {
    elements: any[] | null | undefined;
    publicationRange:
      | ObjectiveTemplateItemOptions
      | Maybe<ObjectiveTemplateItemOptions>
      | null
      | undefined;
    customPublicationRanges:
      | CustomPublicationRanges[]
      | Maybe<CustomPublicationRanges>[]
      | null
      | undefined;
  },
) => {
  return (
    hasElement(args.elements) &&
    ((args.publicationRange === ObjectiveTemplateItemOptions.Any &&
      args.customPublicationRanges?.length &&
      args.customPublicationRanges?.includes(targetPublicationRange)) ||
      args.publicationRange === ObjectiveTemplateItemOptions.Required)
  );
};

export const getDefaultCustomPublicationRanges = (
  context: ObjectiveSourceTypes | undefined,
  objectiveSetting: Maybe<CompanyObjectiveSetting> | undefined,
  user: Maybe<User> | undefined,
  template: ObjectiveTemplate | undefined | null,
) => {
  //TEAMSETTING
  if (!user || context === ObjectiveSourceTypes.Team || context === ObjectiveSourceTypes.Company) {
    return [];
  }

  const {
    publicationRangeDirectManager = ObjectiveTemplateItemOptions.Any,
    publicationRangeLineManagers = ObjectiveTemplateItemOptions.Any,
    publicationRangeTeam = ObjectiveTemplateItemOptions.Any,
  } = template || {};

  const defaultCustomPublicationRanges: SearchSourceValue[] = [];
  const customPublicationRanges = takeAwayMaybeElement<CustomPublicationRanges[]>(
    objectiveSetting?.customPublicationRanges,
  );

  const managers = getActiveUsers(user?.managers);
  const reportLineManagers = getActiveUsers(user?.reportLineManagers);
  const teams = getActiveTeams(user?.teams);

  if (
    isPublicationRequired(CustomPublicationRanges.Team, {
      elements: teams,
      publicationRange: publicationRangeTeam,
      customPublicationRanges,
    })
  ) {
    teams.forEach(team => {
      defaultCustomPublicationRanges.push({
        type: 'team',
        id: team.id,
      });
    });
  }

  if (
    isPublicationRequired(CustomPublicationRanges.Manager, {
      elements: managers,
      publicationRange: publicationRangeDirectManager,
      customPublicationRanges,
    })
  ) {
    managers.forEach(m => {
      defaultCustomPublicationRanges.push({
        type: 'user',
        id: m.id,
      });
    });
  }

  if (
    isPublicationRequired(CustomPublicationRanges.ReportLine, {
      elements: reportLineManagers,
      publicationRange: publicationRangeLineManagers,
      customPublicationRanges,
    })
  ) {
    reportLineManagers.forEach(m => {
      if (!defaultCustomPublicationRanges.some(cpr => cpr.id === m.id)) {
        defaultCustomPublicationRanges.push({
          type: 'user',
          id: m.id,
        });
      }
    });
  }

  return defaultCustomPublicationRanges;
};

export const getDefaultPublicationRangeFromContext = (
  context: ObjectiveSourceTypes,
  objectiveSetting: Maybe<CompanyObjectiveSetting>,
) => {
  let defaultPublicationRange = objectiveSetting?.publicationRange || PublicationRanges.Public;

  if (context === ObjectiveSourceTypes.Team || context === ObjectiveSourceTypes.Company) {
    defaultPublicationRange = PublicationRanges.Public;
  }
  return defaultPublicationRange;
};

export const updateObjectiveFormValuesOnCategoryChange = (
  form: UseFormReturn<any, any>,
  template: ObjectiveTemplate,
  sourceType: ObjectiveSourceTypes | null | undefined,
  publicationRangeOptions: {
    user: Maybe<User> | undefined;
    objectiveSetting: Maybe<CompanyObjectiveSetting> | undefined;
    context: ObjectiveSourceTypes | undefined;
    keepCustomPublicationRangesValues?: boolean;
  },
  teamSetting: Partial<Team> | null | undefined,
) => {
  const { setValue, trigger, getValues, clearErrors } = form;
  const values = getValues();
  const defaultKeyResults = [
    {
      title: '',
      indicator: {
        type:
          template?.indicatorType === ObjectiveTemplateIndicatorTypeOptions.Any
            ? ResultIndicatorTypes.Bool
            : templateIndicatorTypeToIndicatorType(template.indicatorType!),
        goalValue: '',
        initialValue: '',
        isDone: false,
      },
    },
  ];

  const { indicator, keyResults = [] } = values || {};
  const {
    publicationRange,
    resultType,
    keyResultWeight,
    indicatorType,
    parentOkr,
    weight,
    achievementRateType,
    externalLinks,
    parentTeam,
  } = template || {};

  /*非表示*/
  if (parentOkr === ObjectiveTemplateItemOptions.Hidden) {
    setValue('parent', null);
  }

  if (weight === ObjectiveTemplateItemOptions.Hidden) {
    setValue('weight', null);
  }

  if (externalLinks === ObjectiveTemplateItemOptions.Hidden) {
    setValue('externalLinks', []);
  }

  if (parentTeam === ObjectiveTemplateItemOptions.Hidden) {
    setValue('parentTeamId', null);
  }

  /*達成度の計測方法*/
  let valueResultType: ObjectiveResultTypes = values.resultType;

  if (resultType === ObjectiveTemplateResultTypeOptions.Rate) {
    valueResultType = ObjectiveResultTypes.RateIndicator;
    if (indicator?.type !== ObjectiveTemplateResultTypeOptions.Rate) {
      setValue('indicator', undefined);
    }
  } else if (resultType === ObjectiveTemplateResultTypeOptions.Number) {
    valueResultType = ObjectiveResultTypes.NumberIndicator;
    if (indicator?.type !== ObjectiveTemplateResultTypeOptions.Number) {
      setValue('indicator', undefined);
    }
  } else if (resultType === ObjectiveTemplateResultTypeOptions.KeyResults) {
    valueResultType = ObjectiveResultTypes.KeyResults;

    (() => {
      const _keyResults = (
        keyResults?.length
          ? (keyResults || []).filter((keyResult: any) => {
              if (indicatorType === ObjectiveTemplateIndicatorTypeOptions.Any) {
                return true;
              }
              return (
                keyResult?.indicator?.type === templateIndicatorTypeToIndicatorType(indicatorType!)
              );
            })
          : defaultKeyResults
      ).map((keyResult: any) => {
        const updatedKeyResult = { ...keyResult };
        if (keyResultWeight === ObjectiveTemplateItemOptions.Hidden) {
          updatedKeyResult.weight = null;
        }

        return updatedKeyResult;
      });

      setValue('indicator', undefined);
      setValue('keyResults', _keyResults);
      if (keyResultWeight === ObjectiveTemplateItemOptions.Required) {
        setValue('useKeyResultWeight', true);
      } else if (keyResultWeight === ObjectiveTemplateItemOptions.Hidden) {
        setValue('useKeyResultWeight', false);
      }
    })();
  }

  const clearCustomPublicationErrors = () => {
    clearErrors(['customPublicationRanges', 'templateCustomPublicationRanges']);
  };

  /*公開範囲*/
  if (
    sourceType === ObjectiveSourceTypes.Company ||
    (sourceType === ObjectiveSourceTypes.Team && !teamSetting?.enableCustomPublicationRanges)
  ) {
    setValue('publicationRange', PublicationRanges.Public);
    setValue('customPublicationRanges', []);
    setValue('parentTeamId', undefined);
    process.nextTick(() => {
      clearCustomPublicationErrors();
    });
  } else {
    const _publicationRange =
      (publicationRange !== ObjectiveTemplatePublicationRangeOptions.Any
        ? publicationRange
        : values.publicationRange) || PublicationRanges.Public;

    setValue('publicationRange', _publicationRange);

    if (_publicationRange === PublicationRanges.Public) {
      setValue('customPublicationRanges', []);
      process.nextTick(() => {
        clearCustomPublicationErrors();
      });
    } else if (_publicationRange === PublicationRanges.Customize) {
      const defaultCustomPublicationRanges = getDefaultCustomPublicationRanges(
        publicationRangeOptions.context,
        publicationRangeOptions.objectiveSetting,
        publicationRangeOptions.user,
        template,
      );

      if (publicationRangeOptions.keepCustomPublicationRangesValues) {
        setValue('customPublicationRanges', values.customPublicationRanges);
      } else {
        setValue('customPublicationRanges', defaultCustomPublicationRanges);
      }
    }
  }

  /*達成度の入力*/
  if (achievementRateType === ObjectiveTemplateAchievementRateTypeOptions.Auto) {
    setValue('achievementRateType', OkrAchievementRateTypes.Auto);
    if (valueResultType === ObjectiveResultTypes.NumberIndicator) {
      valueResultType = ObjectiveResultTypes.RateIndicator;
    }
  } else if (achievementRateType === ObjectiveTemplateAchievementRateTypeOptions.Manual) {
    setValue('achievementRateType', OkrAchievementRateTypes.Manual);
  }

  setValue('resultType', valueResultType);
  trigger('customPublicationRanges');
};

export const customPublicationRangesValidation = (
  dict: any,
  user: User,
  form: UseFormReturn<any, any>,
  template: ObjectiveTemplate,
  publicationRange: ObjectiveTemplatePublicationRangeOptions,
  customPublicationRanges: SearchSourceValue[],
) => {
  const { trigger, setError, clearErrors } = form;

  if (publicationRange === ObjectiveTemplatePublicationRangeOptions.Public) {
    return;
  }

  const reportLineManagerIds = getArrayIds(getActiveUsers(user?.reportLineManagers));
  const directManagerIds = getArrayIds(getActiveUsers(user?.managers));
  const teamIds = getArrayIds(getActiveTeams(user?.teams));

  const customPublicationRangesUserIds = getArrayIds(
    customPublicationRanges.filter(c => c.type === 'user'),
  );
  const customPublicationRangesTeamsIds = getArrayIds(
    customPublicationRanges.filter(c => c.type === 'team'),
  );

  if (
    template.publicationRangeLineManagers === ObjectiveTemplateItemOptions.Required &&
    !reportLineManagerIds.some(id => customPublicationRangesUserIds.includes(id))
  ) {
    setError('templateCustomPublicationRanges', {
      type: 'lineManagersRequired',
      message: i18next.t('直属以上のマネージャーを設定してください'),
    });
  } else if (
    template.publicationRangeDirectManager === ObjectiveTemplateItemOptions.Required &&
    !directManagerIds.some(id => customPublicationRangesUserIds.includes(id))
  ) {
    setError('templateCustomPublicationRanges', {
      type: 'directManagerRequired',
      message: i18next.t('マネージャーを設定してください'),
    });
  } else if (
    template.publicationRangeTeam === ObjectiveTemplateItemOptions.Required &&
    !teamIds.some(id => customPublicationRangesTeamsIds.includes(id))
  ) {
    setError('templateCustomPublicationRanges', {
      type: 'teamRequired',
      message: i18next.t('所属{{wTeam}}を設定してください', dict),
    });
  } else {
    clearErrors('templateCustomPublicationRanges');
  }
  trigger('templateCustomPublicationRanges');
};

export const getSourceTypeName = (
  sourceType: Maybe<ObjectiveSourceTypes> | undefined,
  dict: UseDictionnaryReturn,
): string => {
  switch (sourceType) {
    case ObjectiveSourceTypes.User:
      return i18next.t('個人');
    case ObjectiveSourceTypes.Team:
      return i18next.t('{{wTeam}}', dict);
    case ObjectiveSourceTypes.Company:
      return i18next.t('会社');
    default:
      return '';
  }
};

export const getIndicatorFromOkr = (okr: Okr): ResultIndicator | null =>
  (okr as Objective | KeyResult)?.indicator || null;

export const getIndicatorInitialValue = (indicator: ResultIndicator | null): number => {
  if (!indicator) {
    return 0;
  }
  if (indicator.type === ResultIndicatorTypes.Number) {
    return (indicator as any).initialValue ?? 0;
  }
  return 0;
};

export const getIndicatorGoalValue = (indicator: ResultIndicator | null): number => {
  if (!indicator) {
    return 100;
  }
  if (indicator?.type === ResultIndicatorTypes.Number) {
    return (indicator as any)?.goalValue ?? 100;
  }
  return 100;
};

export const getIndicatorCurrentValue = (indicator: ResultIndicator | null): number => {
  if (indicator?.type === ResultIndicatorTypes.Bool) {
    return (indicator as any)?.isDone ? 100 : 0;
  }
  return (indicator as any)?.value;
};
