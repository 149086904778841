import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FullscreenModalBody } from '../../../molecules/FullscreenModal';
import LinkButton from '../../../atoms/LinkButton';
import AppLogo from '../../../atoms/AppLogo';
import Url from '../../../../constants/url';

import {
  Layout,
  LayoutInner,
  LayoutTopbar,
  LayoutTopbarContent,
} from '../../../molecules/FullscreenModal/styled';
import Box from '../../../quarks/Box';
import Typo from '../../../quarks/Typo';
import Heading from '../../../quarks/Heading';
import RefreshIcon from '../../../quarks/icons/Refresh';

export const Error404Topbar = () => (
  <LayoutTopbar>
    <LayoutTopbarContent>
      {/* @ts-expect-error */}
      <Link to={Url.index}>
        <AppLogo />
      </Link>
    </LayoutTopbarContent>
  </LayoutTopbar>
);

const Error404Wrap = styled.div`
  height: calc(100vh - 162px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Error404Content = styled.div`
  text-align: center;
`;

const Error404 = () => {
  const { t } = useTranslation();
  return (
    <Layout variant="style-01">
      <LayoutInner>
        <Error404Topbar />
        <FullscreenModalBody>
          <Error404Wrap>
            <Error404Content>
              <Box>
                <img
                  src="/img/vector/not-found.svg"
                  alt="404 Not Found"
                  width="252px"
                  height="166px"
                  className="mx-auto"
                />
              </Box>

              <Box>
                <Heading variant="h1" color="text-02" align="center">
                  404 NOT FOUND
                </Heading>
              </Box>

              <Box align="center" between="small">
                <Typo pre color="text-02" variant="body-02">
                  {t(
                    'お探しのページが見つかりませんでした。\nご指定のページは削除されたか、移動した可能性がございます。',
                  )}
                </Typo>
              </Box>

              <Box align="center" between="small">
                <LinkButton variant="light" icon={<RefreshIcon />} href={Url.index}>
                  {t('トップページに戻る')}
                </LinkButton>
              </Box>
            </Error404Content>
          </Error404Wrap>
        </FullscreenModalBody>
      </LayoutInner>
    </Layout>
  );
};

export default Error404;
