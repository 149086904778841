import { useMemo, useCallback } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import { takeAwayMaybeElement, hasElement } from '../utils/array';

import {
  createBrowserHistory,
  LocationDescriptorObject,
  LocationState,
  Location,
  Path,
  Action,
} from 'history';
import { useLocaleStorage } from './local-storage';

let pastLocations: Location[] = [];

export const history = createBrowserHistory();
export function useRouter<T = Record<string, string>, T2 = Record<string, string>>() {
  const [_, setSidebarOpen] = useLocaleStorage('drawer', false); //eslint-disable-line

  const params = useParams() as T;
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const parsed = { ...queryString.parse(location.search) };

  const query: T2 = {} as T2;

  Object.keys(parsed).forEach(key => {
    (query as any)[key] = decodeURIComponent(parsed[key] as string);
  });

  const appLocationListener = useCallback(
    (location: Location, action: Action) => {
      setSidebarOpen(false);
      switch (action) {
        case 'PUSH':
          pastLocations.push(location);
          break;
        case 'REPLACE':
          pastLocations[pastLocations.length - 1] = location;
          break;
        case 'POP': {
          pastLocations.pop();
          const appLocation = pastLocations[pastLocations.length - 1];
          if (!(appLocation && appLocation.key === location.key)) {
            pastLocations = [location];
          }
          break;
        }
        default:
      }
    },
    [setSidebarOpen],
  );

  const isPreviousLocationWithinApp = useCallback((): boolean => {
    return pastLocations.length > 1;
  }, []);

  const goBackOrReplace = useCallback(
    (location: Path | LocationDescriptorObject, state?: LocationState): void => {
      if (isPreviousLocationWithinApp()) {
        history.goBack();
      } else {
        history.replace(location as Path, state);
      }
    },
    [isPreviousLocationWithinApp, history],
  );

  const getGoBackUrlWithinApp = useCallback(
    (url: string, except: undefined | string[] = []) => {
      const lastLocationWithDiffPathname = [...pastLocations]
        ?.reverse()
        ?.find(
          pl =>
            pl.pathname !== location.pathname &&
            !(except || []).some(ex => ex.includes(pl.pathname)),
        );

      return lastLocationWithDiffPathname
        ? `${lastLocationWithDiffPathname.pathname}${lastLocationWithDiffPathname.search || ''}`
        : url;
    },
    [location],
  );

  const getUrlWithCurrentQuery = useCallback(
    (url: string, keepQueryKey: undefined | string[] = []) => {
      const copyQuery: Record<string, string> = {};
      takeAwayMaybeElement<string[]>(keepQueryKey).forEach(key => {
        if (key in (query as any)) {
          copyQuery[key] = (query as Record<string, string>)[key];
        }
      });

      const split = hasElement(Object.keys(copyQuery)) ? (url.includes('?') ? '&' : '?') : '';
      const queryObj = new URLSearchParams(copyQuery);

      if (queryObj.has('page')) {
        queryObj.set('page', '1');
      }
      const queryStr = queryObj.toString();

      return `${url}${queryObj ? `${split}${queryStr}` : ''}`;
    },
    [query],
  );

  const getUrlWithPreviousPageQueries = useCallback(
    (pathname: string, keys: string[]) => {
      let link = pathname;
      let linkUpdated = false;
      const reversedPastLocations = [...pastLocations].reverse();
      const pastLocation = reversedPastLocations.find(l => l.pathname === pathname);

      if (pastLocation) {
        const searchProps = pastLocation.search?.slice(1)?.split('&') || [];
        if (keys.length && searchProps.length) {
          searchProps.forEach((prop, index) => {
            const split = pathname?.includes('?') || linkUpdated ? '&' : '?';

            const [key, value] = prop.split('=');
            if (keys.includes(key)) {
              link = link + `${split}${key}=${value}`;
              linkUpdated = true;
            }
          });
        }
      }
      return link;
    },
    [pastLocations],
  );

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query,
      params,
      match,
      location,
      history,
      pastLocations,
      goBackOrReplace,
      getGoBackUrlWithinApp,
      appLocationListener,
      getUrlWithCurrentQuery,
      getUrlWithPreviousPageQueries,
    };
  }, [
    params,
    match,
    location,
    history,
    query,
    goBackOrReplace,
    appLocationListener,
    getGoBackUrlWithinApp,
    getUrlWithCurrentQuery,
    getUrlWithPreviousPageQueries,
  ]);
}
