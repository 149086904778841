import { FC, ReactNode } from 'react';
import { icons, Button } from 're-design';
import { useTranslation } from 'react-i18next';

import {
  BaseSideSheetHeader,
  BaseSideSheetHeaderInner,
  BaseSideSheetHeaderInnerIcon,
  BaseSideSheetHeaderInnerBack,
  BaseSideSheetHeaderInnerHeading,
  BaseSideSheetHeaderInnerHeadingMeta,
  BaseSideSheetHeaderInnerActions,
  BaseSideSheetHeaderInnerActionsItem,
} from './styled';
import { hasElement } from '../../../utils/array';
import { useCloseSideSheet } from '../../../lib/sidesheet';
import Heading from '../../quarks/Heading';
import Breadcrumb, { BreadcrumbLabel } from '../../quarks/Breadcrumb';

interface SideSheetHeaderProps {
  title: ReactNode;
  icon?: ReactNode;
  actions?: ReactNode;
  sticky?: boolean;
  onBack?(): any;
  hasPreviousSideSheets?: boolean;
  meta?: ReactNode;
  breadcrumbLabels?: BreadcrumbLabel[];
  onClose?: () => void;
}

export const SideSheetHeader: FC<SideSheetHeaderProps> = ({
  title,
  icon,
  actions,
  sticky,
  meta,
  breadcrumbLabels = [],
  onClose,
  onBack,
}) => {
  const { t } = useTranslation();
  const closeSideSheet = useCloseSideSheet();

  return (
    <BaseSideSheetHeader sticky={sticky}>
      <BaseSideSheetHeaderInner>
        {!!onBack && (
          <BaseSideSheetHeaderInnerBack>
            <Button
              icon={<icons.AngleArrowLeft />}
              onlyIcon
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                if (onBack) {
                  onBack();
                }
              }}
            >
              {t('戻る')}
            </Button>
          </BaseSideSheetHeaderInnerBack>
        )}

        {icon ? <BaseSideSheetHeaderInnerIcon>{icon}</BaseSideSheetHeaderInnerIcon> : null}

        <BaseSideSheetHeaderInnerHeading>
          {hasElement(breadcrumbLabels) && <Breadcrumb labels={breadcrumbLabels} />}
          <Heading
            variant="h3"
            className="leading-none text-ellipsis whitespace-nowrap overflow-hidden"
          >
            {title}
          </Heading>
          {meta && (
            <BaseSideSheetHeaderInnerHeadingMeta>{meta}</BaseSideSheetHeaderInnerHeadingMeta>
          )}
        </BaseSideSheetHeaderInnerHeading>

        <BaseSideSheetHeaderInnerActions>
          {actions && (
            <BaseSideSheetHeaderInnerActionsItem>{actions}</BaseSideSheetHeaderInnerActionsItem>
          )}
          <BaseSideSheetHeaderInnerActionsItem>
            <Button
              variant="transparent"
              icon={<icons.CloseIcon />}
              onlyIcon
              onClick={onClose || closeSideSheet}
            >
              {t('閉じる')}
            </Button>
          </BaseSideSheetHeaderInnerActionsItem>
        </BaseSideSheetHeaderInnerActions>
      </BaseSideSheetHeaderInner>
    </BaseSideSheetHeader>
  );
};
export default SideSheetHeader;
