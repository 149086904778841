import i18next from 'i18next';

import { toast } from '../../components/atoms/Toast';
import RefreshIcon from '../../components/quarks/icons/Refresh';

const showVersionUpToast = () => {
  if (process.env.NODE_ENV === 'production' && !toast.isActive('versionUpLink')) {
    toast.info(
      i18next.t(
        'Wistantの新しいバージョンがリリースされました。ここをクリックして画面を更新してください',
      ),
      {
        toastId: 'versionUpLink',
        autoClose: false,
        closeButton: <RefreshIcon size="18px" pathClassName="text-white fill-current" />,
        onClick: () => {
          if (caches) {
            caches.keys().then(function (names) {
              for (const name of names) caches.delete(name);
            });
          }
          window.location.reload();
        },
      },
    );
  }
};

export default showVersionUpToast;
